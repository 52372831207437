
.teacher-header{
    width: 100%;
    height: 60px;
    /*margin: 0 auto;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: start;
    .teacher-avatar-logo{
        width: 216px;
        height: 60px;
        background:#685EF5;
        display: flex;
        align-items: center;
        justify-content: center;
        span{
            color: #333;
            font-size: 14px;
            height: auto;
            display: flex;
            align-items: center;
            margin-right:4px;
        }
        .teacher-menu{
            img{
                width: 40px;
                height: 30px;
                /*border-radius: 50%;*/
                cursor: pointer;
            }
        }
        .teacher-avatar-title{
            font-size:18px;
            font-weight: 300;
            display: inline-block;
            color: #fff;
        }
    }
    .teacher-left{
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        height: 60px;
        box-shadow:0px 0px 2px 0px rgba(51,51,51,0.12);
        .teacher-avatar-user{
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-right: 60px;
            .teacher-avatar{
                display: flex;
                height: 32px;
                margin-right: 20px;
                line-height: 32px;
                img{
                    max-width:100%;
                    max-height: 100%;
                    border-radius: 50%;
                    margin-right: 8px
                }
                .teacher-name{
                    font-size: 12px;
                    font-weight: 400;
                }
            }
            .teacher-icon{
                display: flex;
                line-height: 24px;
                margin-right: 10px;
                & i{
                    color: #3C4167;
                    font-size: 24px;
                    margin-right:4px;
                }
                &:hover.student-icon{
                    color: #409eff;
                }
                &:hover.teacher-icon{
                    color: #409eff;
                }
                &:hover.teacher-icon i{
                    color: #409eff;
                }
            }
        }
    }
}
