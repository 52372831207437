
.left-title {
    font-weight: 500;
    font-size: 18px;
    padding: 20px 20px 20px 22px;
}
.left-nav {
    .el-menu-item {
        padding: 0!important;
        height: 46px;
        line-height:46px;
        border-left: 2px solid transparent;
        .nav-item {
            padding: 0 20px;
            display: block;
            color: #333;
        }
        &.is-active {
            height:46px;
            line-height:46px;
            .nav-item {
                color: #1E34E4;
            }
        }
        &.is-active:after{
            position: absolute;
            content: '';
            height: 14px;
            width: 3px;
            background: #1E34E4;
            top: 16px;
        }
        &.el-menu-item:focus, &.el-menu-item:hover {
            outline: 0;
            background-color: transparent;
        }

    }
}
.goodsManage-container {
    width: 1%;
    flex: 1;
    background: #fff;
    border-radius: 8px;
}
