/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
    font-family: 'iconfont';  /* Project id 1194474 */
    src: url('//at.alicdn.com/t/c/font_1194474_px96gjh2hj.woff2?t=1726724079380') format('woff2'),
         url('//at.alicdn.com/t/c/font_1194474_px96gjh2hj.woff?t=1726724079380') format('woff'),
         url('//at.alicdn.com/t/c/font_1194474_px96gjh2hj.ttf?t=1726724079380') format('truetype');
  }
.iconfont{
    font-family:"iconfont";
    font-size:16px;font-style:normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
}
.icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}
