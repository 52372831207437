
.student-header {
    width: 100%;
    height: 60px;
    /*margin: 0 auto;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: start;

    .student-avatar-logo {
        width: 240px;
        height: 60px;
        background: #3C4167;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            color: #333;
            font-size: 14px;
            height: auto;
            display: flex;
            align-items: center;
            margin-right: 4px;
        }

        .student-menu {
            img {
                width: 40px;
                height: 30px;
                /*border-radius: 50%;*/
                cursor: pointer;
            }
        }

        .student-avatar-title {
            font-size: 18px;
            font-weight: 300;
            display: inline-block;
            color: #fff;
        }
    }

    .student-left {
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        height: 60px;
        box-shadow: 0px 0px 2px 0px rgba(51, 51, 51, 0.12);

        .student-avatar-user {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-right: 20px;

            .student-avatar {
                display: flex;
                height: 32px;
                line-height: 32px;
                margin-right: 10px;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    border-radius: 50%;
                    margin-right: 8px
                }

                .student-name {
                    font-size: 12px;
                    font-weight: 400;
                }
            }

            .student-icon {
                display: flex;
                line-height: 24px;
                margin-right: 10px;

                & i {
                    color: #9d9ea7;
                    font-size: 24px;
                    margin-right: 4px;
                }

                &:hover.student-icon {
                    color: #409eff;
                }

                &:hover.student-icon i {
                    color: #409eff;
                }
            }
        }
    }
}
