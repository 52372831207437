
.nav {
    flex: 1;
    width: 1%;
    border-bottom: none;
    .el-menu-item {
        padding: 0;
        .nav-item {
            display: block;
            padding: 0 20px;
        }
    }
}
.top-sign-out{
    cursor: pointer;
}
