
    .student-content{
        display: flex;
        /*justify-content: space-around;*/
        /*flex-direction: column;*/
    }
.left-wrapper{
    .class-list {
        height: 1%;
        flex: 1;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    ::v-deep.el-menu{
        background-color: transparent;
        border-right: none;
    }
    ::v-deep.el-col-12 {
        width: 100%;
    }
    ::v-deep.el-submenu__title{
        background-color: #fff;
        line-height: 60px;
        height: 60px;
        span{
          color: #3A3567;
          font-size: 16px;
        }
        .iconfont{
          font-size: 20px;
          color: #564CED;
          margin-right: 20px;
        }
        .el-submenu__icon-arrow {
            margin-right: 0px;
            width: 24px;
            text-align: center;
            vertical-align: middle;
            -webkit-text-fill-color: #7886CC;
        }
        &:hover{
           //background-color: transparent;
        }
    }
    ::v-deep.el-menu-item a{
        color: #3A3567;
        font-size: 14px;
    }
    ::v-deep.el-menu li.el-menu-item-group ul li.el-menu-item{
        background-color: #F8F8FD;
        border-right: 2px solid transparent;
        color: #3A3567;
        //&:focus, &:hover{
        //  background-color: #F1F0FE;
        //}
    }
  ::v-deep.el-menu li.el-menu-item-group .el-menu-item-group__title{
    display: none;
  }
    ::v-deep.el-submenu .el-menu-item {
        //padding-left: 44px;
        height: 60px;
        line-height: 60px;
    }
    ::v-deep.el-menu li.el-menu-item-group ul li.is-active {
        background-color: #F1F0FE;
        border-right: 4px solid #564CED;
        color: #fff;
    }
    ::v-deepa:focus{
        color: #564CED;
    }
    .nav-item {
        //padding: 0 20px;
        text-align: center;
        display: block;
        // color: #fff;
    }
    .work-title{
        height: 56px;
        line-height: 56px;
        position: relative;
        -webkit-box-sizing: border-box;
        white-space: nowrap;
        list-style: none;
        padding-left: 20px;
        font-size: 14px;
        cursor: pointer;
        transition: border-color .3s,background-color .3s,color .3s;
        box-sizing: border-box;
        color: #fff;
    }
}
    .student-content {
        /*margin-left: 4px;*/
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;

        .left-wrapper {
            width: 216px;
            background-color: #fff;
            height: 100%;
        }

        ::v-deep .right-wrapper {
            width: 1%;
            flex: 1;
            //padding: 20px;
            //overflow: auto;
            & > .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            & > .el-scrollbar__wrap > .el-scrollbar__view {
                min-width: 1000px;
            }

            .right-wrapper-content {
                //min-width: 1000px;
                //height: 100%;
                padding: 20px;
            }
        }

        .main-wrapper {
            background: #fff;
            border-radius: 4px;
            height: 100%;
            width: 1%;
            flex: 1;
            display: flex;
            flex-direction: column;
            padding-bottom: 10px;
            box-sizing: border-box;
        }
    }
    .el-menu-data-board{
        background-color: #F8F8FD;
      &:hover{
        background-color: #F8F8FD;
      }
      a{
        color: #3A3567;
        &:hover,&:focus{
          color: #3A3567;
        }
      }
    }
    ::v-deep .el-menu-item-group__title{
        padding: 0px !important;
    }
