
    .student-header {
        width: 100%;
        height: 60px;
        /*margin: 0 auto;*/
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: start;

        .student-avatar-logo {
            width: 221px;
            height: 60px;
            background: #685EF5;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                color: #333;
                font-size: 14px;
                height: auto;
                display: flex;
                align-items: center;
                margin-right: 4px;
            }

            .student-menu {
                img {
                    width: 40px;
                    height: 30px;
                    /*border-radius: 50%;*/
                    cursor: pointer;
                }
            }

            .student-avatar-title {
                font-size: 18px;
                font-weight: 300;
                display: inline-block;
                color: #fff;
            }
        }

        .student-left {
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 1;
            height: 60px;
            box-shadow: 0px 0px 2px 0px rgba(51, 51, 51, 0.12);

            .student-avatar-user {
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-right: 20px;

                .student-avatar {
                    display: flex;
                    height: 32px;
                    line-height: 32px;
                    margin-right: 10px;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        border-radius: 50%;
                        margin-right: 8px
                    }

                    .student-name {
                        font-size: 12px;
                        font-weight: 400;
                    }
                }

                .student-icon {
                    display: flex;
                    line-height: 24px;
                    margin-right: 10px;

                    &:nth-of-type(2) {
                        i {
                            font-size: 16px;
                            margin-right: 6px;
                        }
                    }

                    & i {
                        color: #9d9ea7;
                        font-size: 24px;
                        margin-right: 4px;
                    }

                    &:hover.student-icon {
                        color: #409eff;
                    }

                    &:hover.student-icon i {
                        color: #409eff;
                    }
                }
            }
        }
    }
    .VideoDetail-dialog {
            .video-detail {
                /*height: 700px;*/

            }
            ::v-deep .el-dialog {
                border-radius: 10px;
                .el-dialog__header {
                    background-color: #F1F0FE;
                    border-radius: 10px 10px 0 0;
                }
                .el-dialog__body {
                    padding: 20px;
                }
                .el-dialog__footer {
                    text-align: center;
                    .dialog-footer {
                        .el-button {
                            padding: 7px 28px;
                            border-radius: 15px;
                        }
                        .determine-btn {
                            background: #594FEE;
                            border-color: #594FEE;
                            &:hover {
                                background: #2338E6;
                                border-color: #2338E6;
                            }
                        }
                    }
                }
                .header {
                    display: flex;
                    .video-cover {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 300px;
                        height: 200px;
                        margin-right: 20px;
                        cursor: pointer;
                        img.cover {
                           max-width: 100%;
                            max-height: 100%;
                        }
                        img.play-btn {
                            position: absolute;
                            width: 40px;
                            height: 40px;
                        }
                    }
                   
                }
                .VideoDetail-btn {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 20px 0;
                    .play-comment-like {
                        color: #594FEE;
                        .item {
                            margin-right: 20px;
                        }
                    }
                    .btn-del-video {
                        color: #FB0360;
                        i {
                            margin-right: 10px;
                            font-size: 14px;
                        }
                    }
                }
                .operating-video {
                    color: #5F5C74;
                }
                .comment-content {
                    margin: 20px 0;
                    border-top: 1px solid #EBEBF9;
                    .comment-header {
                        color: #5F5C74;
                        font-size: 16px;
                        margin: 20px 0;
                        display: block;
                    }
                    .comment {
                        height: 182px;
                        ::v-deep .el-scrollbar__wrap {
                            overflow-x: hidden;
                        }
                        span.no-data {
                            display: block;
                            text-align: center;
                        }
                        
                      
                    }
                }
                .did-not-pass {
                    color: #FB0338;
                    .time {
                        margin-bottom: 10px;
                        span:nth-child(1) {
                            margin-right: 20px;
                        }
                    }
                    .detail {
                        span:nth-child(1) {
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
        .PublishVideo-dialog {
            ::v-deep .el-dialog__body {
                // height: 126px;
                .type {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .el-radio__input.is-checked .el-radio__inner {
                         border-color: #DCDFE6;
                        background: transparent;
                    }
                    .el-radio__input.is-checked .el-radio__inner::after {
                        width: 6px;
                        height: 6px;
                        background-color: #9ACC5B;
                    }
                    .el-radio__input.is-checked+.el-radio__label {
                        color: #606266;
                    }
                }
            }
        }

::v-deep .el-form-item--feedback .el-input__validateIcon{
    display: none;
}
